import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SideContainer from "../../PrimaryMenu/components/SideContainer";
import PageLoader from "../../util/Loader/Loader";
import NewsFeed from "../../util/News/NewsFeed";
import DLC from "../../Article/DLC/DLC";
import DLCDon from "../../Article/DLC/DLCDon";
import axios from "axios";

function SandboxSide(props) {
  console.log('hihi', props)
  const [category, setCategory] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [currentArticle, setCurrentArticle] = useState(null);
  const { id } = useParams();
  const revisedTitle = "Gray Zone Research & Intelligence"

  const [token, setToken] = useState(null);
  const generateToken = async () => {
    const {
      data: { token },
    } = await axios.post(
      `https://skytop-strategies.com/wp-json/jwt-auth/v1/token?username=${process.env.REACT_APP_WP_USERNAME}&password=${process.env.REACT_APP_WP_PASSWORD}`
    );
    setToken(token);
  };



  useEffect(() => {
    const selectCurrentArticle = (data) => {
      if (Array.isArray(data)) {
        return data.find((article) => article.id === parseInt(id));
      } else {
        return data;
      }
    };
    setCurrentArticle(selectCurrentArticle(props.articles));
    setCategory('Cyber');
    setLoaded(true);
    generateToken();
  }, [id, props.articles]);
  return (
    <div className="text-left pl-3">
      {loaded ? (
        <>
          {/* <SideContainer
            conferences={props.filterByCategory(props.conferences, category)}
            article={true}
          /> */}
          <br/>
        <hr/>
        {/* <img style={{width: "285px"}}className="dlc-box poll-container" src="https://skytop-strategies.com/wp-content/uploads/2022/08/Ulsch-472905754-1660331018254.jpg"/> */}
        {/* <img style={{width:"100%", marginBottom:"0"}} className="dlc-box poll-container" src="https://skytop-strategies.com/wp-content/uploads/2022/08/Ulsch-472905754-1660331018254.jpg"/> */}


              {/* <DLC
                title={revisedTitle}
                title2={currentArticle.acf.dlc.dlc_title2}
                title3={currentArticle.acf.dlc.dlc_title3}
                image={currentArticle.acf.dlc.dlc_image}
                file={currentArticle.acf.dlc.dlc_file}
              /> */}

              <h1 className="gz-headline">Gray Zone Research</h1>

              <DLCDon
                token={token}
                title={revisedTitle}
                title2="Research Report"
                title3="Satellite-Based E-Commerce for Franchising to the Developing World"
                background="https://skytop-strategies.com/wp-content/uploads/2022/08/Satellite-based-e-commerce.jpg"
              />
              <DLCDon
                token={token}
                title={revisedTitle}
                title2="Research Report"
                title3="Solar Power Satellites (SPS) for Energy Sustainability"
                background="https://skytop-strategies.com/wp-content/uploads/2022/08/Solar-Power.jpg"
              />
              <DLCDon
                token={token}
                title={revisedTitle}
                title2="Research Report"
                title3="The Bytes that Feed a Resource-Starved, Hungry Planet"
                background="https://skytop-strategies.com/wp-content/uploads/2022/08/The-byter.jpg"

              />
              <DLCDon
                token={token}
                title={revisedTitle}
                title2="Research Report"
                title3="Climate Change and Competitive Shipping Transport"
                background="https://skytop-strategies.com/wp-content/uploads/2022/08/Climate-change.jpg"

              />
              <DLCDon
                token={token}
                title={revisedTitle}
                title2="Research Report"
                title3="Atoms for Africa: A Continental Cyber Attack Force Through
                    Nuclear Development"
                background="https://skytop-strategies.com/wp-content/uploads/2022/08/Atoms-for-africa.jpg"

              />
              <DLCDon
                token={token}
                title={revisedTitle}
                title2="Research Report"
                title3="Smart City Development and Predictive Policing Export"
                background="https://skytop-strategies.com/wp-content/uploads/2022/08/Smart-City.jpg"

              />
              <DLCDon
                token={token}
                title={revisedTitle}
                title2="Research Report"
                title3="Innovative Acquisition, Battery Development and the Lithium Lock"
                background="https://skytop-strategies.com/wp-content/uploads/2022/08/Innotative-acquisition.jpg"

              />
              <DLCDon
                token={token}
                title={revisedTitle}
                title2="Research Report"
                title3="The China Syndrome Shaping the Military-Commercial Fusion"
                background="https://skytop-strategies.com/wp-content/uploads/2022/08/The-China-Syndrome.jpg"
              />
              <DLCDon
                token={token}
                title={revisedTitle}
                title2="Research Report"
                title3="Russia Cooperation Agreement and A New World Order"
                background="https://skytop-strategies.com/wp-content/uploads/2022/08/Russia-Cooperation.jpg"

              />
              <DLCDon
                token={token}
                title={revisedTitle}
                title2="Research Report"
                title3="Closing the Innovation Gap Through Key Tech Transfer Alliances"
                background="https://skytop-strategies.com/wp-content/uploads/2022/08/Closing-the-Innovation-Gap.jpg"

              />
              <DLCDon
                token={token}
                title={revisedTitle}
                title2="Research Report"
                title3="China-Japan Strategy: Mystery Investment Funds"
                background="https://skytop-strategies.com/wp-content/uploads/2022/08/China-Japan-Strategy.jpg"

              />
              <DLCDon
                token={token}
                title={revisedTitle}
                title2="Survey Poll"
                title3= "Climate Change, Food and Military-Civil Fusion"
                background="https://skytop-strategies.com/wp-content/uploads/2022/08/Climate-change-food....jpg"

              />
              <DLCDon
                token={token}
                title={revisedTitle}
                title2="Survey Poll"
                title3= "U.S. Challenge: China Research Joint Ventures and Tech Transfer"
                background="https://skytop-strategies.com/wp-content/uploads/2022/08/U.S.-Challenge.jpg"

              />
              <DLCDon
                token={token}
                title={revisedTitle}
                title2="Survey Poll"
                title3= "LinkedIn China Polls: The Russia-Ukraine War Influence"
                background="https://skytop-strategies.com/wp-content/uploads/2022/08/LinkedIn-Polls.jpg"

              />


          <div className="mt-5">
          
          </div>
        </>
      ) : (
        <PageLoader />
      )}
    </div>
  );
}

export default SandboxSide;