import { Link } from 'react-router-dom';
import "./styles.css";



const UnderVideo = (id) => {
  const filterUnder = () => {

        if(id.id === 3035) {
return (
  <>
    <div className="under-munter">
      <img alt="boxSetImg" className="skytop-logo" src="https://skytop-strategies.com/wp-content/uploads/2022/04/point-copy.png"/>

     <div className="desc-text">
     <p> Presents</p>
     <p><strong> Jeremy Fielding, CEO, Kekst CNC</strong></p>
     <p> Along with Maryann Bruce, Board Director and Former CEO</p>
     <p> Reimagining Work</p>
     <p> What is Workplace and How Will it Affect Companies</p>

     </div>

     <div className="brought">
     {/* <p className="p">Brought to You by</p> */}
            <img alt="boxSetImg" className="company-logo" src="https://skytop-strategies.com/wp-content/uploads/2023/02/Logo_white-on-purple.png"/>
       </div>

     </div>

   </>
)
        } else if(id.id === 3030) {
          return (
            <>
            <div className="under-powell">
      <img alt="boxSetImg" className="skytop-logo" src="https://skytop-strategies.com/wp-content/uploads/2022/04/point-copy.png"/>

     <div className="desc-text">
     <p> Presents</p>
     <p><strong>Ethan Powell, Founder and CEO, Impact Shares</strong></p>
     <p> Money Talks</p>
     <p> Investing In Social Change</p>

     </div>

     <div className="brought">
     {/* <p className="p">Brought to You by</p> */}
            <img alt="boxSetImg" className="impact-logo" src="https://skytop-strategies.com/wp-content/uploads/2022/03/impactShares.png"/>
       </div>

     </div>

            </>
          )

        } else {
          return (
            <>
            <div className="under-munter">
      <img alt="boxSetImg" className="skytop-logo" src="https://swerthmi.sirv.com/SKYTOP/digital_programs/skytop%20at%20the%20table.png"/>
            <div className="desc-text-munter">
            <p> Presents</p>
            <p><strong> Cameron Munter, U.S. Ambassador (retired)</strong></p>
            <p> Along with Ian J. Brzezinski, Senior Fellow, Atlantic Council, </p>
            <p>and other Special Guests</p>
            <p className="trial"> World Affairs for Global Business Leaders</p>

            </div>

            <div className="brought">
            {/* <p className="p">Brought to You by</p> */}
            <img alt="boxSetImg" className="filler-logo" src="https://skytop-strategies.com/wp-content/uploads/2022/04/yourLogo-382118962-1647035773606.jpg"/>
            </div>

            </div>
            </>
          )

        }

  }
return (
<>
{filterUnder()}
     </>
 )
};

export default UnderVideo;