import React, { useEffect, useState } from "react";
import "./styles.css";
import Feature from "../Section/PageComponents/Feature/Feature";
import Recents from "../Section/PageComponents/Recents/Recents";
import SubFeatures from "../Section/PageComponents/SubFeatures/SubFeatures";
import ViewAllBtn from "../ViewAll/ViewAllBtn";
import Advert from "../util/Advert/Advert";
import AdvertMain from "../util/Advert/AdvertMain";
import Calendar from "../util/Calendar/Calendar";
import { sendGAPageView } from "../util/GoogleAnalytics";
import RecentArticle from "../Section/PageComponents/Recents/RecentArticle";
import RecentSurvey from "../Survey/RecentSurvey";
import ProgramLogos from "../util/ProgramLogos/ProgramLogos";
import Advert2 from "../util/Advert/Advert2";
import ProgramPreviews from "../Section/PageComponents/Recents/ProgramPreviews";
import { Link } from "react-router-dom";
import { scrollToTop } from "../util/scrollToTop";

function Home(props) {
  const investment = props.programs[1].acf;
  const cyber = props.programs[2].acf;
  const CSR = props.programs[3].acf;
  const capital_markets = props.programs[4].acf;
  const activism = props.programs[5].acf;
  const washington_world = props.programs[6].acf;
  const survey = props.survey;

  const [articlesIds, setArticlesIds] = useState([]);

  

  //Use this for auto-population of new articles

  async function getArticles() {
    try {
      const response = await fetch(
        "https://skytop-strategies.com/wp-json/wp/v2/articles?_fields[]=title&_fields[]=acf&_fields[]=content&_fields[]=date&_fields[]=id&_fields[]=_links&_embed=wp:featuredmedia&per_page=6&order=desc&orderby=date",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Unable to fetch articles from WordPress.");
      }

      const data = await response.json();
      console.log("get articles data ===", data);
      setArticlesIds(data.map((article) => article.id));
      data.forEach((article) => {
        console.log("=== article.date", article.date);
        const date = new Date(article.date);
        console.log(
          date.toLocaleString("en-US", { timeZone: "America/New_York" })
        );
      });
    } catch (error) {
      console.error(error);
      return [];
    }
  }
  // console.log("???ACF", article.acf.category)

  useEffect(() => {
    getArticles();
  }, []);

  
  const [
    //survey
    ,setSurvey] = useState(null);
  useEffect(() => {
      sendGAPageView("/headlines");
      const findSurvey = (articles) => {
          setSurvey(articles.find(art => art.acf.isSurvey === true));
      }
      findSurvey(props.articles);
  },[props.articles])

  return (
    <>
      <div className="home-grid">
        <div className="feature">
          <br />
          <br />
          <img
            alt=""
            id="adBorder"
            style={{ width: "95%", borderRadius: "10px" }}
            src="https://skytop-strategies.com/wp-content/uploads/2023/09/n-macdonnell-ulsch-composite.jpg"
          />

          {/* <iframe
        height="600"
        width="800"
        className="video"
        src="https://www.youtube.com/embed/joThKhu5bnw"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe> */}
          {/* </div> */}
          {/* <a href={"https://youtu.be/mpQoHjq9e9c"}>
            <img
              onClick={() => scrollToTop()}
              alt=""
              style={{ width: "100%", marginTop: "1rem", marginBottom: "2rem" }}
              src="https://skytop-strategies.com/wp-content/uploads/2022/10/Sarah-McPhee-Thumbnail.jpg"
            />
          </a> */}
          {/* <Advert
            image={
              "https://skytop-strategies.com/wp-content/uploads/2022/07/SA-Red.jpg"
            }
            link={"/activism"}
          /> */}
          {/* <Advert
            image={
              "https://skytop-strategies.com/wp-content/uploads/2022/03/esg.jpg"
            }
            link={"https://practicalesg.com/begin-your-membership-skytop/"}
          /> */}
          {/* <Feature title={props.title} video={props.video} /> */}
        </div>
        <div className="ad">
          <ProgramPreviews
            programs={props.programs}
            data={activism}
            moneyData={investment}
            conferences={props.conferences}
          />
        </div>

        {/* //Use this to auto-populate new articles */}
        {/* <div className="subfeature">
          <SubFeatures
            articles={props.articles}
            ids={articlesIds}
            comments={props.comments}
          />
        </div> */}
        <div className="subfeature">
          <SubFeatures
            articles={props.articles}
            ids={[7120, 7105, 7101]}
            comments={props.comments}
          />
        </div>
        {/* <div className="recent">
  {props.articles.map((article, index) => (
    <div key={index}>
      <img src={article.acf.image} alt={article.title.rendered} />
      <h2>{article.title.rendered}</h2>
      <p>{article.acf.custom_byline}</p>
      <p>{article.acf.date}</p>
      <p>{article.acf.category}</p>
      <p>{article.acf.author}</p>
      <p>{props.comments[index]}</p>
    </div>
  ))}
  <ViewAllBtn linkTo={"article"} />
</div> */}

        <div className="recent">
          <Recents
            // changeActiveCategory={props.changeActiveCategory}
            articles={props.articles} 
            ids={[7112, 7116, 7117, 4396, 7161, 7123, 7118, 7096]} 
            comments={props.comments}
          />
          <ViewAllBtn linkTo={"article"} />
        </div>
      </div>
      {/* <Advert image={props.ad} link={props.ad_link} /> */}
      {/* <Advert
            image={
              "https://skytop-strategies.com/wp-content/uploads/2022/03/esg.jpg"
            }
            link={"https://practicalesg.com/begin-your-membership-skytop/"}
          /> */}
      {/* <div className="mt-4">
        <Advert
          image={
            "https://skytop-strategies.com/wp-content/uploads/2022/06/SP-S1-Collage-Resize-970x250-02.jpg"
          }
          link={"https://www.spglobal.com/en/"}
        />
      </div> */}
      {/* <div>
        <Advert2 />
      </div> */}
      <hr />


      {survey && (
        <div className="mt-5 mb-5">
          <RecentSurvey
            id={survey.id}
            image={survey.acf.image}
            category={survey.acf.category}
            title={survey.title.rendered}
            custom_byline={survey.acf.custom_byline}
            author={survey.acf.author}
            date={survey.acf.date}
            comments={props.comments}
            excerpt={survey.acf.excerpt}
          />
        </div>
      )}
    </>
  );
}

export default Home;
