// export const sponsorNames = ['Aberdeen Standard',
//     'Akin Gump',
//     'Alliance Bernstein',
//     'Alvarez & Marsal',
//     'Aon',
//     'Appleseed Capital',
//     'AQR',
//     'AXA Investment Managers',
//     'Baker McKenzie',
//     'Brunswick Group',
//     'Calvert Impact Capital',
//     'University of Cambridge',
//     'CenterPoint Energy',
//     'Cleary Gottlieb',
//     'Clorox',
//     'Coca-Cola',
//     'Darktrace',
//     'Davies',
//     'Deloitte',
//     'Deutsche Bank',
//     'Domini Impact Investments',
//     'Dow Chemical',
//     'Edelman',
//     'EY',
//     'Forcepoint',
//     'Franklin Templeton',
//     'FTI Consulting',
//     'Global Cyber Security Center',
//     'Georgeson',
//     'Glass Lewis',
//     'Grant & Eisenhofer',
//     'Heuking Kuhn Luer Wojtek',
//     'ICGN',
//     'Israel Defense',
//     'ISS Corporate Solutions',
//     'Joele Frank',
//     'Jones Day',
//     'JP Morgan',
//     'Kane Kessler',
//     'KEKST CNC',
//     'Kleinberg Kaplan',
//     'KPMG',
//     'Lazard Frere',
//     'Lockheed Martin',
//     'Macmillan',
//     'MFS',
//     'Microsoft',
//     'MSCI',
//     'Nasdaq',
//     'Natixis',
//     'Nestle Waters',
//     'Norton Rose Fulbright',
//     'Pfizer',
//     'PWC',
//     'SASB',
//     'Schulte Roth & Zabel',
//     'Sidley Austin',
//     'Standard & Poor’s',
//     'State Street Bank/SSGA',
//     'Thomson Reuters',
//     'Trillium Asset Management',
//     'Wachtel Lipton Rosen & Katz'
// ]

export const sponsorNames = 
[  'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/1ftp_Primary_Horizontal_Blue%20(2).png',
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/1200px-Altria_logo.svg.png',
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/50-50wob.png',
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/100%20WOMEN%20IN%20FINANCE_Horizontal_Logo_RGB.png',
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/1563376263542.png',
// 'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/AccountAbility.png',
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/A%26M_Stacked_color.png',
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/aberdeen.jpeg',
// 'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/eyJidWNrZXQiOiJhbGlnbmFibGV3ZWItcHJvZHVjdGlvbiIsImtleSI6ImJaccordo.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/ACTIVIST_INSIGHT.png',
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/activistmonitor-vector-logo.png',
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/abernathy%20mcgregor.png',
// 'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/abraham%20trading%20company.png',
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/agility_exec-1.png',
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/action-inclusion-1.png',
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/ADD%20GENDER.png',
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/aee-logo.png',
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/akin-gump.png',
// 'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/ALBOURNE%20VILLAGEpng.png',
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/AllenMatkins_Blue_High_Res_square.png',
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/ALLIANCE%20BERNSTEIN.png',
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/alliance-for-water-stewardship-vector-logo.png',
// 'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/AIMA.png',
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/Amenity-Featured-Image.png',
// 'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/ANNALY.png',
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/ANTEAGROUPpng.png',
// 'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/AON.png',
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/Apana-Tagline-400x400-1.png',
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/appleseed_capital.png',
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/American-Investment-Council-logo_i.png',
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/AS_logo_horiz-1024x234.png',
// 'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/APLHA%20PINEpng.png',
// 'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/appomattox.png',
// 'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/AQR.png',
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/ardsley-partners_owler_20160227_113400_original.png',
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/Arctic_Wolf_Logo.png',
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/asbc_logo.png',
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/ruepzy6scgm3umxwv655.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/AXA-Investment-Manager-logo.png',
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/barclayhedge-logo-3-in.png',
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/Baker_McKenzie_logo_(2016).svg.png',
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/bluetractor.png',
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/blue-tritonpng.png',
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/blbg-1024x539.png',
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/boldly..png',
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/BordenLadnerGervais.png',
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/breakthroughresults.png',
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/brunswick.png',
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/calvert.png',
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/canopy.png',
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/causecast.png',
// 'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/CAASA.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/CDP.png',
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/the%20csr%20group.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/cleary-gottlieb-resized.png',
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/CMi2i%2BLogo%2BColour%2Bwith%2BTag%2BLarge.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/CSRHUB.png',
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/DAVIES-LOGO_1200X630.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/dwm-logo-fin-24in.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/df-king_logo_final.png',
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/DOW.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/E2.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/EEI.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/Edelman_PR_firm_logo.gif', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/ethical-markets.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/ExtraHop_Logo_besk-e1613734585541.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/New-FAIRR-logo-square-low-res.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/fieldfisher_logo.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/FigBytes-Logo-1024x296.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/finsbury.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/fortune_empire.gif',
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/fried_frank-1024x538.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/FTI_Consulting.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/future500.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/georgeson-vector-logo.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/gladstone.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/glass_lewis.png',
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/GEC-logo-New-small.png',
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/grant_eisenhofer-1024x650.png', 'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/GRESB-1.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/Haystackneedle.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/logo_heuking.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/HKIRA.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/Impact_Shares_Logo-10.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/impakt-logo-big.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/ICM-Institute-for-crisis-managementpng.png','https://swerthmi.sirv.com/SKYTOP/sponsor_logos/Internet_Security_Alliance.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/IH2O_PNG.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/investorcom.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/isos%20group.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/joele-frank-logo.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/kekst_new_web.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/kleinbergkaplanpng.png',
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/KPMG_logo.svg_-1024x424.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/Lazard_Corporate_Blue.png',
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/league%20of%20allies.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/lowey-1.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/MACKENZIEPARTNERS.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/Moodys-ESG_Logo_RGB_Blue.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/monet.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/morgan_lewis.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/morganstanley.png',
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/morningstar.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/Morrow-Sodali-Logo.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/NSU-logo-full-color.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/the-nature-conservancy.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/northstarassetmanagement.png',
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/Norton_Rose_Fulbright_logo.svg.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/OkapiPartners-Logo.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/pacificinstitute.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/press-logos-pyxera-compressor.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/QWAFAFEW_400dpiLogo.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/radiantesg.png',
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/RFK%20Human%20Rights-logo-1.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/s-factor.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/S%20Network.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/s%26Pglobal.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/SATT.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/schulterothzabel.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/SEAS-Logo.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/shechange.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/sloan-valve-company-vector-logo.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/sloane%26company.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/svc_owler_20200908_155457_original.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/Thompson%20Hine.png', 'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/vinson-elkins-logo.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/wlrk.png', 
'https://swerthmi.sirv.com/SKYTOP/sponsor_logos/wgob-logo-pinktransparent-e1563994644927.png'
]