import { Link } from "react-router-dom";
import { scrollToTop } from "../scrollToTop";

function Advert2(props) {
  return (
    // <div id="border">
      <Link to={"/articles/6641"}>
        <div onClick={() => scrollToTop()} className="advertRow">
        <img
              className="soldierImg"
              src="https://i0.wp.com/skytop-strategies.com/wp-content/uploads/2023/04/newGrass.png"
              alt="ad"
            />
          {/* <div className="advertColumn">
            
          </div> */}

          {/* <div style={{marginTop:"52px"}} className="advertColumn"> */}
          {/* <div className="">
        <img
          className="familImg"
          style={{width:"150px"}}
          src="https://skytop-strategies.com/wp-content/uploads/2022/07/F4F-LOGO-ICON-BLUE-AND-RED_Small-1.png"
          alt="ad"
        />
        <br/>
        <br/>
      </div> */}
            {/* <div className="familQuotes">
              <p>
                <h2>Investing In Space</h2><h3>Impact Seeded Inside Innovation</h3>
                <br/>
                <i>
                Impact investing is generally defined as an investment strategy that seeks to generate returns while also creating a positive social or environmental impact. Typically, there is an understanding that an impact investment will have a lower rate of financial return because of the tradeoff between social and financial priorities. 
                </i>
              </p>
            </div> */}
            {/* <button id="f4fLogo" class="previewButtonAuth">Read More</button> */}



          {/* </div> */}
        </div>
        </Link>
    // </div>
  );
}

export default Advert2;
{/* <div id="border">
<a href="https://familusa.org/" target="_blank" rel="noreferrer">
  <div className="advertRow">
    <div className="advertColumn">
      <img
        className="soldierImg"
        src="https://skytop-strategies.com/wp-content/uploads/2022/04/IMG_1945.jpg"
        alt="ad"
      />
    </div>

    <div className="advertColumn">
      <div className="">
        <img
          className="familImg"
          src="https://skytop-strategies.com/wp-content/uploads/2022/04/famil_logo_vert-2-1.png"
          alt="ad"
        />
      </div>

      <div className="familQuotes">
        <p>
          <i>
            "They served alongside U.S. troops for two decades.
            <br />
            They risked their lives to protect American lives."
          </i>
          <br />
          <b>
            LTG John F. Mulholland (USA, Ret), FAMIL Advisory Board Member{" "}
          </b>
        </p>
      </div>

      <div className="familQuotes">
        <p>
          <i>
            "Now at home in the United States,
            <br />
            our Afghan partners strive for the American dream."
          </i>
          <br />
          <b>
            Geeta Bakshi, Former US Intelligence Officer, Founder of FAMIL
          </b>
        </p>
      </div>

      <div className="familQuotes">
        <p>
          <i>
            “Join us in helping these great patriots, who risked their all
            for their country and ours, as they start their new lives as
            Americans.”
          </i>
          <br />
          <b>
            Mike Barry, Retired Senior US Intelligence Official, FAMIL
            Board of Directors
          </b>
        </p>
      </div>
    </div>
  </div>
</a>
</div> */}