import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { scrollToTop } from '../../util/scrollToTop';
import "../styles.css";
import DLCDonForm from "../../Article/DLC/DLCDonForm";
import DLCDonFormConsult from "../../Article/DLC/DLCDonFormConsult";

import axios from "axios";


const Sandbox = (props) => {
  const [token, setToken] = useState(null);
  const generateToken = async () => {
    const {
      data: { token },
    } = await axios.post(
      `https://skytop-strategies.com/wp-json/jwt-auth/v1/token?username=${process.env.REACT_APP_WP_USERNAME}&password=${process.env.REACT_APP_WP_PASSWORD}`
    );
    setToken(token);
  };
  console.log('hiuuho', token)

  useEffect(() => {
    generateToken();
  }, []);


  const { title, number, sub_title, logo, build } = props;
  return (
    <>
    <div className="gz-top">
    <img className="gz-logo" src="https://skytop-strategies.com/wp-content/uploads/2022/08/Ulsch-472905754-e1678732874285.jpg" alt="author"></img>
  <div className="gz-bio">
    <div className="bio-text">
      <p>
  Gray Zone advises its global clients on China’s cyber activities, economic espionage and the threat
  those activities pose to intellectual property, corporate reputation and business goals. Gray Zone’s subscription offering features
  deep research and bespoke consulting engagements led by N. MacDonnell Ulsch, an advisor to the CIA and other US government entities
  on cyber risk and a former Senior Managing Director of PwC’s global cybercrime practice.
  </p>
  </div>
    <div className="bio-btn">
        <button
          // onClick={() => generateToken()}
          onClick={() => {}}
          className="btn btn-primary comment-btn btn-lg mt-4 lm"
          data-toggle="modal"
          data-target="#dlcModal2"
        >
          Learn More <i className="fas"></i>
        </button>
        </div>


      <DLCDonForm  token={token} title={props.title3} />
      <DLCDonFormConsult  token={token} title={props.title3} />
  </div>

    </div>
    <div className="gray-zone-mid">
    {/* <img className="author-img" src="https://skytop-strategies.com/wp-content/uploads/2022/03/unnamed-1.png" alt="author"></img> */}

    </div>
    <div className="ulsch-name-title">

    <br></br>
    <h1>N. MacDonnell Ulsch</h1>
    <h1>Founder & Chief Analyst, Gray Zone Research & Intelligence</h1>
    <br></br>
    <br></br>

     </div>

     <div className="ulsch-bio">
     <img className="author-img" src="https://skytop-strategies.com/wp-content/uploads/2022/03/unnamed-1.png" alt="author"></img>

<div>
<p className="bottom-copy">Founder & Chief Analyst, Gray Zone Research & Intelligence—China Series

Guest Lecturer on Cyber Warfare, US Military Academy at West Point

Research Fellow in Cyber Security at Boston College

US Senate Committee on Foreign Relations Advisor</p>

<p className="bottom-copy">Gray Zone’s founder is N. MacDonnell (Don) Ulsch, a SkyTop Contributing Author, with a deep understanding of China’s technology-driven strategic intent and growth architecture. He is a well-known international advisor on cybersecurity and risk issues in industry and national security. He advises various public policy elected officials in the United States, including the U.S. Senate Foreign Relations Committee, and government officials in allied countries, on China. He periodically advises the U.S. Senate Committee on Foreign Relations on the China cyber and technology transfer threat and is a former Senior Managing Director of PwC’s cybercrime practice who has led incident investigations in 70 countries.
Previously he was with the National Security Institute and under the Foreign Intelligence Surveillance Act he served as a cyber threat advisor to the U.S. Central Intelligence Agency. His work there involved developing perspective on key cyber adversary capabilities and attack vectors on the U.S. commercial sector and Defense Industrial Base. He is the author of several widely read books and helped found the Master’s Degree program in cybersecurity at Boston College.
</p>
</div>

     </div>
     <div class="consultation-btn-container">
  <button
    onClick={() => {}}
    className="btn btn-primary btn-lg"
    data-toggle="modal"
    data-target="#dlcModal4"
    >
    For further information and to book a free consultation with N. MacDonnell Ulsch, click here.
  </button>
</div>

    </>
  );
};

export default Sandbox;