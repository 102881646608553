import React, {useEffect} from 'react';
import ConfCard from '../Conference/components/ConfCard';
import Recents from '../Section/PageComponents/Recents/Recents';
import { sendGAPageView } from '../util/GoogleAnalytics';
import './styles.css';

function ViewAll(props){
    console.log('flight status', props)
    useEffect(() => {
        sendGAPageView(window.location.pathname);
    },[])

    const renderData = () => {
        if (props.articles){
            return(
                <>
                    <Recents articles={props.articles} comments={props.comments}/>
                </>
            )
        }else{
            return(
                <>
                    {!props.safeflight ? props.conferences.map(conf =>
                        <ConfCard
                            past={false}
                            id={conf.id}
                            imgUrl={conf.acf.location_image ? conf.acf.location_image : conf._embedded["wp:featuredmedia"][0].source_url}
                            title={conf.title.rendered}
                            tagline={conf.acf.tagline}
                            city={conf.acf.city}
                            date={conf.acf.date}
                            custom_date={conf.acf.custom_date}
                        />
                    ) : props.conferences.map(conf =>
                        conf.id === 6400 ?
                        <ConfCard
                            past={false}
                            id={conf.id}
                            imgUrl={conf.acf.location_image ? conf.acf.location_image : conf._embedded["wp:featuredmedia"][0].source_url}
                            title='Operation SafeFlight'
                            tagline='Co Chaired by Chuck Brooks​, Professor, Georgetown University, CEO Brooks Consulting, Skytop Contributing Author​.
                            Co-Chair, Arik Arad​, Partner at Stonecourt Capital and World Renowned Aviation Security Expert'
                            city={conf.acf.city}
                            date={conf.acf.date}
                            custom_date={conf.acf.custom_date}
                        /> : ''
                    )}
                </>
            )
        }
    }
    return (
        <div className="view-all-container">
            {renderData()}
        </div>
    )
}

export default ViewAll;

