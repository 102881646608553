import { Link } from "react-router-dom";
import { scrollToTop } from "../../../util/scrollToTop";
import Advert2 from "../../../util/Advert/Advert2";
import UnderVideo from "./UnderVideo.jsx";
import ViewAll from "../../../ViewAll/ViewAll";

import "./styles.css";

const ProgramPreviews = (props) => {
  console.log("hii", props);
  const { link, ttpStyle, attStyle, itsStyle } = props;

  const {
    title,
    image,
    ttp_author,
    ttp_title,
    ttp_sub,
    ttp_pre_name,
    ttp_name,
    ttp_logo,
    att_author,
    att_title,
    att_sub,
    att_pre_name,
    att_name,
    att_logo,
    its_author,
    its_title,
    its_sub,
    its_pre_name,
    its_name,
    its_logo,
  } = props.data;

  // const {
  //   ttp_author,
  //   ttp_title2,
  //   ttp_sub2,
  //   ttp_pre_name2,
  //   ttp_name2,
  //   ttp_logo2
  // } = props.moneyData;

  return (
    <div>
      {/* id="border" */}
      <div className="container">
        <br />
        {/* <iframe
        height="600"
        width="800"
        className="video"
        src="https://www.youtube.com/embed/UqjfM6GVJ3w"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe> */}
        {/* <iframe
        height="600"
        width="800"
        className="video"
        src="https://www.youtube.com/embed/a9eQV8XUtPE"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>

<UnderVideo id={'none'}/> */}

        {/* <h2  >
          <b style={{fontSize: 30}}>What You Need to Know in Less than 30 minutes</b>
        </h2>
        <h3 >
          <i style={{fontSize: 26}}>Hosted by</i>
        </h3>
        <h2 >
          <b style={{fontSize: 26}}>Industry Experts</b>
        </h2> */}
        {/* <br /> */}
        {/* <br></br>
        <br></br> */}
        <hr></hr>
        <br></br>
        <br></br>
        <br></br>
        <div className="roww">
          {/* At The Table */}
          {/* <div id="back" className="logo col-sm"> */}

          {/* <Link to={`/program-authors/3211`}>
              <div onClick={() => scrollToTop()} id="progCard">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <img
                      className="table-top"
                      src="https://swerthmi.sirv.com/SKYTOP/digital_programs/skytop%20at%20the%20table.png"
                      alt=""
                    />
                    <div className="program-blurb">
                      <h4>World Affairs</h4>
                      <i>For Global Business Leaders</i>
                      <br />
                      <br />
                      Hosted by
                      <br />
                      <i>Ambassador Cameron Munter (retired)</i>
                      <br />
                      <hr />
                      Brought to You by
                      <br />
                      <br />
                      <img
                        alt=""
                        className="yourLogo"
                        src="https://skytop-strategies.com/wp-content/uploads/2022/04/yourLogo-382118962-1647035773606.jpg"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Link> */}

          {/* <Link to={`/program-authors/${props.moneyData.ttp_author}`}>
              <div onClick={() => scrollToTop()} id="progCard">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <img
                      className="point-top"
                      src="https://swerthmi.sirv.com/SKYTOP/digital_programs/skytop%20to%20the%20point.png"
                      alt=""
                    />
                    <hr style={{ marginBottom: "-6px" }}></hr>
                    <div className="program-blurb">
                      <h4>{props.moneyData.ttp_title}</h4>
                      <i>{props.moneyData.ttp_sub}</i>
                      <br />
                      <br />
                      {props.moneyData.ttp_pre_name}
                      <br />
                      <i>{props.moneyData.ttp_name}</i>
                      <br />
                      <hr />
                      Brought to You by
                      <br />
                      <br />
                      <img
                        alt=""
                        className={ttpStyle}
                        src={props.moneyData.ttp_logo}
                        />
                    </div>
                  </div>
                </div>
              </div>
            </Link> */}

          {/* </div> */}

          {/* <iframe
        height="600"
        width="800"
        className="video"
        src="https://www.youtube.com/embed/Vu3b9wYnT-s"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe> */}
          <iframe
            style={{ border: "2px solid black" }}
            height="600"
            width="800"
            className="video"
            src="https://www.youtube.com/embed/W4qzSxIM-Dk"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>

          <br></br>

          <Link to={`/digi-prog`}>
            <h3 style={{ paddingBottom: "10px" }}>
              <button onClick={() => scrollToTop()} class="previewButtonAuth">
                More Live, Streamed or On Demand Programs
              </button>
            </h3>
          </Link>
          <br></br>
          <br></br>
          <br></br>
          <br></br>

         <div className="TTPHomepage">

          <img src="https://i0.wp.com/skytop-strategies.com/wp-content/uploads/2022/03/table.png" alt="at the table logo" className='table-top-auth'/>
          <h2 className='cardTitle'>China's Chess Game: Where is Russia on the Gameboard?</h2>
          <p className='cardSubTsitle'><em>Explore how China may step in and capitalize Russia following sanctions imposed by the West
            <br /><strong>Part Two</strong></em></p>
          <iframe
            style={{ border: "2px solid black" }}
            height="600"
            width="800"
            className="TTPHomepageVideo"
            src="https://www.youtube.com/embed/g2tDVqpvocc"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          </div>

          {/* <Link to={"/conferences/6400"}>
           <img
            alt=""
            id="adBorder"
            style={{ width: "95%", borderRadius: "10px"}}
            src="https://skytop-strategies.com/wp-content/uploads/2023/03/operation-safeflight-head-1.png"
          />
          <ViewAll conferences={props.conferences} safeflight='true'/>
          </Link> */}

          {/* <UnderVideo id={props.programs[1].id}/> */}

          {/* <div className="under-fielding">
      <img alt="boxSetImg" className="kekst-edge" src="https://skytop-strategies.com/wp-content/uploads/2022/04/point-copy.png"/>

     <div className="desc-text">
     <p> Presents</p>
     <p><strong> Jeremy Fielding, CEO, Kekst CNC</strong></p>
     <p> Along with Maryann Bruce, Board Director and Former CEO</p>
     <p> Reimagining Work</p>
     <p> What is Workplace and How Will it Affect Companies</p>
     <p>Brought to You by</p>
     </div>
            <img alt="boxSetImg" className="at" src="https://skytop-strategies.com/wp-content/uploads/2022/04/kekst_new_web.jpg"/>
     </div> */}

          {/* <br />
        <br></br> */}
          <br></br>
          <br></br>
          <br></br>

          {/* <iframe
        height="600"
        width="800"
        className="video"
        src="https://www.youtube.com/embed/VwDq9JJqNUY"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>

      <UnderVideo id={props.programs[5].id}/> */}

          {/* <div className="under-powell">
      <img alt="boxSetImg" className="at" src="https://skytop-strategies.com/wp-content/uploads/2022/04/point-copy.png"/>

     <div className="desc-text">
     <p> Presents</p>
     <p><strong>Ethan Powell, Founder and CEO, Impact Shares</strong></p>
     <p> Money Talks</p>
     <p> Investing In Social Change</p>
     <p>Brought to You by</p>
     </div>
            <img alt="boxSetImg" className="at" src="https://skytop-strategies.com/wp-content/uploads/2022/03/impactShares.png"/>
     </div> */}

          {/* At The Table */}
          {/* <div id="back" className="logo col-sm"> */}

          {/* <Link to={`/program-authors/3779`}>
              <div onClick={() => scrollToTop()} id="progCard">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <img
                      className="table-top"
                      src="https://swerthmi.sirv.com/SKYTOP/digital_programs/skytop%20at%20the%20table.png"
                      alt=""
                    />
                    <div className="program-blurb">
                      <h4>Intelligence Briefing</h4>
                      <i>Emerging Cyber Risks and Best Response Scenarios</i>
                      <br />
                      <br />
                      Hosted by
                      <br />
                      <i>Chuck Brooks</i>
                      <br />
                      <hr />
                      Brought to You by
                      <br />
                      <br />
                      <img
                        alt=""
                        className="yourLogo"
                        src="https://skytop-strategies.com/wp-content/uploads/2022/04/yourLogo-382118962-1647035773606.jpg"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Link> */}
          {/* <Link to={`/program-authors/${ttp_author}`}>
              <div onClick={() => scrollToTop()} id="progCard">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <img
                      className="point-top"
                      src="https://swerthmi.sirv.com/SKYTOP/digital_programs/skytop%20to%20the%20point.png"
                      alt=""
                    />
                    <hr style={{ marginBottom: "-6px" }}></hr>
                    <div className="program-blurb">
                      <h4>{ttp_title}</h4>
                      <i>{ttp_sub}</i>
                      <br />
                      <br />
                      {ttp_pre_name}
                      <br />
                      <i>{ttp_name}</i>
                      <br />
                      <hr />
                      Brought to You by
                      <br />
                      <br />
                      <img
                        alt=""
                        className="kekstLogo"
                        src={ttp_logo}
                        />
                    </div>
                  </div>
                </div>
              </div>
            </Link> */}
          {/* </div> */}

          {/* At The Table
          <div id="back" className="logo col-sm">
          <Link to={`/program-authors/3781`}>
              <div onClick={() => scrollToTop()} id="progCard">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <img
                      className="table-top"
                      src="https://swerthmi.sirv.com/SKYTOP/digital_programs/skytop%20at%20the%20table.png"
                      alt=""
                    />
                    <div className="program-blurb">
                    <h4>Boomerang</h4>
                      <i>The Geopolitics of Business</i>
                      <br />
                      <br />
                      Hosted by
                      <br />
                      <i>Elisabeth Braw, Senior Fellow, American Enterprise Institute</i>
                      <br />
                      <hr />
                      Brought to You by
                      <br />
                      <br />
                      <img
                        alt=""
                        className="yourLogo"
                        src="https://skytop-strategies.com/wp-content/uploads/2022/04/yourLogo-382118962-1647035773606.jpg"
                        />
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ProgramPreviews;
