import { Link, useHistory } from "react-router-dom";
import { scrollToTop } from "../../util/scrollToTop";

const TTPPart = (props) => {
  const { guest, title, number, sub_title, logo, styles, comment, host, show1 } = props;
  const history = useHistory();
  console.log('the parts', props)

  const handleLinkClick = (event) => {
    event.preventDefault();
    window.open(event.target.href, '_blank');
  };
  return (
    <div className="container">
    <br />
    <div className="row">
      <div id="back" className="logo col-sm">
        <Link to="/digi-prog">
          <div onClick={() => scrollToTop()} id="cardAuthPoint">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img
                  className="point-top-auth"
                  src="https://skytop-strategies.com/wp-content/uploads/2022/04/point-copy.png"
                  alt=""
                />
                <div className="program-blurb-auth">
                  <b>
                    <h3> {title}</h3>
                    <i>{number}</i>
                  </b>
                  <br />
                  <br />
                  <h4>
                    {host}
                    <br />
                    {guest}
                  </h4>
                  <br />
                  <h4>
                    {comment}
                  </h4>
                  {/* <hr /> */}
                  <iframe
                    height="600"
                    width="800"
                    className="video"
                    // src={number === "Part 1 of a 3 Part Series" ? "https://www.youtube.com/embed/HNB5-BTIREg"
                    // : number === "Part 2 of a 3 Part Series" ? "https://www.youtube.com/embed/_J6OA5QvdKg"
                    // : number === "Part 3 of a 3 Part Series" ? "https://www.youtube.com/embed/yz3HfFR7UO4" : ''}
                    src={show1}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                  {props.title === "Money Talks: Investing in Social Change" ? (
                    <>
                      <button className="btn btn-primary comment-btn mt-4" onClick={handleLinkClick}>
                        <a href="https://buy.stripe.com/bIY4hU5inazPfscg0p" target="_blank" rel="noopener noreferrer">
                          Register Now for Access
                        </a>
                      </button>
                      <p>Brought to You By</p>
                    </>
                  ) : (
                    <p>Brought to You By</p>
                  )}
                  <br />
                  <br />
                  <img alt="" className={styles} src={logo} />
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  </div>
  );
};

export default TTPPart;
