import React from "react";
import ViewAllBtn from "../../ViewAll/ViewAllBtn";
import "../styles.css";
import SideConference from "./SideConference";
import PastSideConference from "./PastSideConference";

import { Link } from "react-router-dom";
import { scrollToTop } from "../../util/scrollToTop";

function SideContainer(props) {
  const renderConferences = (conferences) => {
    return conferences.map((conference) => (
      <SideConference
        id={conference.id}
        title={conference.title.rendered}
        tagline={conference.acf.tagline}
        imgUrl={
          conference.acf.location_image
            ? conference.acf.location_image
            : conference._embedded["wp:featuredmedia"][0].source_url
        }
        // {if(conference.acf.location_image){
        //   imgUrl = conference.acf.location_image
        // } else if(conference._embedded["wp:featuredmedia"][0].source_url){
        //   imgUrl = conference._embedded["wp:featuredmedia"][0].source_url
        // }}

        // imgUrl={
        //   conference.acf.location_image
        // }
        city={conference.acf.city}
        date={conference.acf.date}
        custom_date={conference.acf.custom_date}
      />
    ));
  };


  const renderPastConferences = (conferences) => {
    let pastConfToRender = conferences.filter(conference => conference.acf.location_image || conference._embedded)


    return pastConfToRender.map((conference) => (
      <PastSideConference
        id={conference.id}
        title={conference.title.rendered}
        tagline={conference.acf.tagline}
        imgUrl={
          conference.acf.location_image
            ? conference.acf.location_image
            : conference._embedded ?
            conference._embedded["wp:featuredmedia"][0].source_url
            : "https://skytop-strategies.com/wp-content/uploads/2022/07/unnamed-7.png"
        }
        city={conference.acf.city}
        date={conference.acf.date}
        custom_date={conference.acf.custom_date}
      />
    ));
  };

  const renderSpotLight = (conferences) => {
    let spotlight = conferences.filter(conference => conference === conferences[0])
    return spotlight.map((spotlight) => (
      <SideConference
        id={spotlight.id}
        title={spotlight.title.rendered}
        tagline={spotlight.acf.tagline}
        imgUrl={
          spotlight.acf.location_image
            ? spotlight.acf.location_image
            : spotlight._embedded["wp:featuredmedia"][0].source_url
        }
        city={spotlight.acf.city}
        date={spotlight.acf.date}
        custom_date={spotlight.acf.custom_date}
      />
    ));
  }

  function decodeHtml(html) {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}

  return (
    <div className="conference-container">
      <div className="conference-spotlight">
        <h3 className="section-title">Conference Spotlight</h3>
        <h6>{decodeHtml(props.conferences[0].title.rendered)}</h6>

        <Link to={`/conferences/${props.conferences[0].id}`}>
          {/* <img
            className="card-img side-img"
            src={
              "https://skytop-strategies.com/wp-content/uploads/2021/07/THE-ONE-FOR-CHICAGO-300x164.jpeg"
            }
            alt="spotlight conference"
          ></img> */}
            <div className="side-conference-overflow">
        {renderSpotLight(props.conferences)}
      </div>
        </Link>
      </div>

{/* <div className="side-conference card">
            <img className="card-img side-img" src={props.imgUrl} alt="side conference"></img>
            <div className="conf-info card-img-overlay">
                <div className="side-data-container text-left">
                    <Link to={`/conferences/${props.id}`}>
                        <p className="side-conf-title"><Markup content={props.title}></Markup></p>
                    </Link>
                    <p className="side-conf-tagline">{props.tagline}</p>
                </div>
                <div className="side-data-container text-right">
                    <p className="side-data m-0">{props.city}</p>
                    {props.custom_date ?
                        <p id="side-date" className="side-data">{props.custom_date}</p>
                    :
                        <p id="side-date" className="side-data">{props.date}</p>
                    }
                </div>
            </div>
        </div> */}

      {/* <p className="section-title">
        {props.article ? "Related Conferences" : "Upcoming Conferences"}
      </p> */}
      <ViewAllBtn linkTo={"conference"} />
      <div className="side-conference-overflow">
        {props.conferences ? renderConferences(props.conferences) : ""}
      </div>


      {/* <p className="section-title">
        {props.article ? "Related Conferences" : "Past Conferences"}
      </p> */}

<div className="view-all-container section-title">
            <div className="view-all comments-box">
                {props.linkTo === "article" ?
                    <Link to="/index/articles"><p onClick={() => scrollToTop()} className="com-text text-light">View All</p></Link>
                    :
                    <Link to="/pastConf"><p onClick={() => scrollToTop()} className="com-text text-light">Past Conference Calendar</p></Link>
                }
            </div>
        </div>

      <div className="side-conference-overflow">
        {props.pastConferences ? renderPastConferences(props.pastConferences) : ""}
      </div>
      {/* <div className="text-right"> */}
      <div style={{ marginLeft: "10px", marginBottom: "20px" }}>
        {/* <ViewAllBtn linkTo={"conference"} /> */}

        {/* <div className="view-all-container">
            <div className="view-all comments-box">
                {props.linkTo === "article" ?
                    <Link to="/index/articles"><p onClick={() => scrollToTop()} className="com-text text-light">View All</p></Link>
                    :
                    <Link to="/pastConf"><p onClick={() => scrollToTop()} className="com-text text-light">Past Conference Calendar</p></Link>
                }
            </div>
        </div> */}

      </div>
    </div>
  );
}

export default SideContainer;
