import { Link, useHistory } from 'react-router-dom';
import { scrollToTop } from '../../util/scrollToTop';

const ProgramPartNoVideo = (props) => {
  const { title, number, sub_title, logo, build } = props;
  const history = useHistory();

  const handleLinkClick = (event) => {
    event.preventDefault();
    window.open(event.target.href, '_blank');
  };

  return (
    <div className="container">
      <br />
      <div className="row">
        <div id="back" className="logo col-sm">
          <Link to="/digi-prog">
            <div onClick={() => scrollToTop()} className="cardAuth">
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  {title === "Under Construction" ? (
                    <img
                      className="table-top-auth2"
                      src="https://skytop-strategies.com/wp-content/uploads/2022/04/under-construction-icon-on-white-background-vector-19719079-908942554-1649180537513.jpg"
                      alt=""
                    />
                  ) : (
                    <img
                      className="table-top-auth2"
                      src="https://skytop-strategies.com/wp-content/uploads/2022/03/table.png"
                      alt=""
                    />
                  )}
                  <div className="program-blurb-auth">
                    <b>
                      {title === "Under Construction" ? (
                        <div className="constructionMargin">
                          <img
                            alt=""
                            className="constructionLogo"
                            src={build}
                          />

                          <br />
                          <br />
                        </div>
                      ) : (
                        <h3> {title}</h3>
                      )}
                      <i>{number}</i>
                    </b>
                    <br />
                    <br />
                    <i>{sub_title}</i>
                    <br />
                    <hr />
                    {/* <button
                      className="btn btn-primary comment-btn mt-4"
                      onClick={handleLinkClick}
                    >
                      <a
                        href="https://buy.stripe.com/bIY4hU5inazPfscg0p"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Register Now for Access
                      </a>
                    </button> */}
                    {/* <p>Brought To You By</p> */}
                    <br />
                    <br />
                    <img alt="" className="yourLogoAuth" src={logo} />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProgramPartNoVideo;
