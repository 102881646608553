import { Link, useHistory } from "react-router-dom";
import { scrollToTop } from "../../util/scrollToTop";

const ProgramPart = (props) => {
  const { title, number, sub_title, logo, build, show1 } = props;
  const history = useHistory();

  const handleLinkClick = (event) => {
    event.preventDefault();
    window.open(event.target.href, "_blank");
  };
  return (
    <div className="cardContainer">
      <br />
      <div className="row">
        <div id="back" className="logo col-sm">
          <Link to="/digi-prog">
            <div onClick={() => scrollToTop()} className="cardAuth">
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  {title === "Under Construction" ? (
                    <img
                      className="table-top-auth"
                      src="https://i0.wp.com/skytop-strategies.com/wp-content/uploads/2022/03/table.png"
                      alt=""
                    />
                  ) : (
                    <img
                      className="table-top-auth"
                      src="https://skytop-strategies.com/wp-content/uploads/2022/03/table.png"
                      alt=""
                    />
                  )}
                  <div className="program-blurb-auth">
                    <div className="test">
                      <b>
                        {title === "Under Construction" ? (
                          <div className="constructionMargin">
                            {/* <img
                              alt=""
                              className="constructionLogo"
                              src={build}
                            /> */}

                            <br />
                            <br />
                          </div>
                        ) : (
                          <h3 className='cardTitle'> {title}</h3>
                        )}
                          <i className='cardSubTitle'>{sub_title}</i>
                        <br />
                        <br />
                        <i className='cardPart'>{number}</i>
                      </b>
                      {/* <p>hello</p> */}
                      {/* <br /> */}
                      {/* <br /> */}
                    
                    </div>
                    {/* <br /> */}
                    {/* <hr /> */}
                    <iframe
                      height="600"
                      width="800"
                      className="video"
                      src={show1}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                    {/* <button className="btn btn-primary comment-btn mt-4" onClick={handleLinkClick}>
  <a href="https://buy.stripe.com/bIY4hU5inazPfscg0p" target="_blank" rel="noopener noreferrer">
    Register Now for Access
  </a>
</button> */}
                    <p>Brought To You By</p>
                    <br />
                    <br />
                    <img alt="" className="yourLogoAuth" src={logo} />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProgramPart;
