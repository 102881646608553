import TTPPart from "./TTPPart";
import { Link } from "react-router-dom";
import { scrollToTop } from "../../util/scrollToTop";

const TTPAuthor = (props) => {
  console.log("ttp props", props.author.guest_name);
  const {
    // auth_img_bio,
    program_logo,
    name,
    // category,
    title,
    sub_title,
    title2,
    sub_title2,
    author_image,
    about_name,
    about_link,
    part_title,
    part_number,
    part_sub_title,
    part_logo,
    part_title2,
    part_number2,
    part_sub_title2,
    part_logo2,
    part_title3,
    part_number3,
    part_sub_title3,
    part_logo3,
    part_title4,
    part_number4,
    part_sub_title4,
    part_logo4,
    // part_title4,
    // part_number4,
    // part_sub_title4,
    // part_logo4,
    // part_title5,
    // part_number5,
    // part_sub_title5,
    // part_logo5,
    // part_title6,
    // part_number6,
    // part_sub_title6,
    // part_logo6,
    // part_title7,
    // part_number7,
    // part_sub_title7,
    // part_logo7,
    // construction_img,
    contributing_author,
    comment,
    guest1,
    guest2,
    guest3,
    guest4,
    trailer,
    show1,
    show2,
    show3,
    show4,
  } = props.author;

  return (
    <>
      {/* <br />
      <div className="stuImgContainer">
        <img
          className="ttpBack"
          alt="studio"
          src="https://skytop-strategies.com/wp-content/uploads/2022/03/blank-layout.jpg"
        />
        <br />
        <br />
        <br />
        <div className="textContainerAuth">
          <br />
          <br />
          <br />
          <img alt="" className="bigTTP" src={program_logo} />
          <h2>
            {title}
            {sub_title}
          </h2>
          <br />
          <h3>
            Christopher P. Skroupa <br />
            Editor-in-Chief, Skytop <br />
            </h3>
            <h5>
              <i>Presents</i>
            </h5>
          <h4 className="ttpPeople">
            <br />
            {name} <br />
            <h5>
              <i>along with</i>
            </h5>
            Maryann Bruce, Board Director and Former CEO
            <br />
            <br />
            Venita Fields, Board Director, Superior Group of Companies (NASDAQ: SGC) and four private companies, Partner, Pelham Sk2 Advisors
          </h4>
        </div>
      </div> */}

      {trailer ? (
        <iframe
          height="600"
          width="800"
          className="boxSetImg"
          src={trailer}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      ) : (
        <img
          src="https://i0.wp.com/skytop-strategies.com/wp-content/uploads/2023/05/skytop-to-the-point-1.png"
          alt="No trailer available"
          className='TTPLogoImg'
        />
      )}

      {/* <iframe
        height="600"
        width="800"
        className="boxSetImg"
        src={trailer} 
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe> */}

      <br></br>
      <br></br>
      <br></br>
      <h4 className="viewStream">View Streamed or On Demand Worldwide</h4>
      <a href="https://share.hsforms.com/1L6rh1vnhT-yFTtjNgXwrEwcw754">
        <button className="previewButtonAuth">
          Register Here to Join Our Program
        </button>
      </a>
      <br />
      <br />
      <hr className="blueLine" />
      <br />
      <h3 className="TTPPart_title">{part_title}</h3>
      <h3>{sub_title2}</h3>
      <br />
      <img className="authorImgBig" alt="" src={author_image} />
      <br />
      <br />

      {contributing_author ? (
        <Link to={`/authors/${contributing_author}`}>
          <button onClick={() => scrollToTop()} className="previewButtonAuth">
            About {about_name}
          </button>
        </Link>
      ) : (
        <a href={about_link}>
          {" "}
          <button onClick={() => scrollToTop()} className="previewButtonAuth">
            About {about_name}
          </button>
        </a>
      )}
      <br />
      <br />
      <hr className="blueLine" />
      <br />

      <TTPPart
        title={part_title}
        number={part_number}
        sub_title={part_sub_title}
        logo={part_logo}
        styles="kekstLogoAuth"
        guest={guest1}
        comment={comment}
        host={name}
        show1={show1}
      />
      <TTPPart
        title={part_title2}
        number={part_number2}
        sub_title={part_sub_title2}
        logo={part_logo2}
        styles="kekstLogoAuth"
        guest={guest2}
        comment={comment}
        host={name}
        show1={show2}
      />
      <TTPPart
        title={part_title3}
        number={part_number3}
        sub_title={part_sub_title3}
        logo={part_logo3}
        styles="kekstLogoAuth"
        guest={guest3}
        comment={comment}
        host={name}
        show1={show3}
      />

      {/* {props.author.show1 > 0 ?
      <TTPPart
        title={part_title}
        number={part_number}
        sub_title={part_sub_title}
        logo={part_logo}
        styles="kekstLogoAuth"
        comment={comment}
        host={name}
        show1={show1}
      /> : ''}

{props.author.show3 > 0 ?
      <TTPPart
        title={part_title3}
        number={part_number3}
        sub_title={part_sub_title3}
        logo={part_logo3}
        styles="kekstLogoAuth"
        guest={guest3}
        comment={comment}
        host={name}
        show3={show3}
      /> : ''}

{props.author.show4 > 0 ?
      <TTPPart
        title={part_title4}
        number={part_number4}
        sub_title={part_sub_title4}
        logo={part_logo4}
        styles="kekstLogoAuth"
        guest={guest4}
        comment={comment}
        host={name}
        show4={show4}
      /> : ''} */}
    </>
  );
};

export default TTPAuthor;
